import i18n from '@/i18n/i18n-setup';
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import { orderBy } from 'lodash';
import DataTable from '@/components/DataTable.vue';
import { BookingRoutes } from '@/Booking/booking.const';
import { useGetVendorsQuery } from '@/generated-types/graphql.types';
import { DATA_TABLE_SETTINGS } from '@/Admin/const';
import VendorAuthorizator from '@/Admin/components/VendorAuthorizator.vue';
import { useGetLocalizedPath } from '@/util/globalHelpers';
const tableLabels = [
    {
        name: 'firstName',
        label: i18n.t('profile.account_page.first_name'),
        sortable: true,
        customElement: 'firstNameSlot'
    },
    {
        name: 'lastName',
        label: i18n.t('profile.account_page.last_name'),
        sortable: true,
        customElement: 'lastNameSlot'
    },
    {
        name: 'phone',
        label: i18n.t('profile.account_page.phone'),
        sortable: true,
        customElement: 'phoneSlot'
    },
    {
        name: 'email',
        label: i18n.t('profile.account_page.email_address'),
        sortable: true,
        customElement: 'emailSlot'
    },
    {
        name: 'organisationName',
        label: i18n.t('profile.account_page.organisation_name'),
        sortable: true,
        customElement: 'organisationNameSlot'
    },
    {
        name: 'action',
        label: i18n.t('admin.action'),
        sortable: false,
        customElement: 'actionSlot'
    }
];
export default defineComponent({
    components: {
        DataTable,
        VendorAuthorizator
    },
    setup() {
        const dataTable = ref(null);
        const { result } = useGetVendorsQuery();
        const vendors = computed(() => result?.value?.get_vendors);
        const selectedVendorId = ref(null);
        watch(() => vendors.value, () => {
            if (vendors.value) {
                dataTable.value = vendors.value?.map(vendor => ({
                    accountId: vendor?.account_id,
                    firstName: vendor?.first_name,
                    lastName: vendor?.last_name,
                    phone: vendor?.phone,
                    email: vendor?.email,
                    organisationName: vendor?.organisation_name
                }));
            }
        }, { immediate: true });
        const updateSort = ({ sortField, sort }) => {
            dataTable.value = orderBy(dataTable.value, [sortField], [sort]);
        };
        const loginAsVendor = (vendor) => {
            selectedVendorId.value = vendor.accountId;
        };
        const selectedVendorAuthorized = () => {
            globalThis.$router.push(useGetLocalizedPath(`${BookingRoutes.BOOKINGS}/${BookingRoutes.VENDOR}`), () => globalThis.$router.go());
        };
        return {
            dataTable,
            selectedVendorId,
            tableLabels,
            tableSettings: computed(() => DATA_TABLE_SETTINGS),
            vendors,
            loginAsVendor,
            selectedVendorAuthorized,
            updateSort
        };
    }
});
