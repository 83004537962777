import i18n from '@/i18n/i18n-setup';
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import { orderBy } from 'lodash';
import DataTable from '@/components/DataTable.vue';
import { useGetCustomersQuery } from '@/generated-types/graphql.types';
import { DATA_TABLE_SETTINGS } from '@/Admin/const';
import BookerAuthorizator from '@/Admin/components/BookerAuthorizator.vue';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { BookingRoutes } from '@/Booking/booking.const';
const tableLabels = [
    {
        name: 'firstName',
        label: i18n.t('profile.account_page.first_name'),
        sortable: true,
        customElement: 'firstNameSlot'
    },
    {
        name: 'lastName',
        label: i18n.t('profile.account_page.last_name'),
        sortable: true,
        customElement: 'lastNameSlot'
    },
    {
        name: 'phone',
        label: i18n.t('profile.account_page.phone'),
        sortable: true,
        customElement: 'phoneSlot'
    },
    {
        name: 'email',
        label: i18n.t('profile.account_page.email_address'),
        sortable: true,
        customElement: 'emailSlot'
    },
    {
        name: 'action',
        label: i18n.t('admin.action'),
        sortable: false,
        customElement: 'actionSlot'
    }
];
export default defineComponent({
    components: {
        BookerAuthorizator,
        DataTable
    },
    setup() {
        const dataTable = ref(null);
        const { result } = useGetCustomersQuery();
        const bookers = computed(() => result?.value?.get_customers);
        const selectedBookerId = ref(null);
        watch(() => bookers.value, () => {
            if (bookers.value) {
                dataTable.value = bookers.value?.map(vendor => ({
                    accountId: vendor?.account_id,
                    firstName: vendor?.first_name,
                    lastName: vendor?.last_name,
                    phone: vendor?.phone,
                    email: vendor?.email
                }));
            }
        }, { immediate: true });
        const updateSort = ({ sortField, sort }) => {
            dataTable.value = orderBy(dataTable.value, [sortField], [sort]);
        };
        const loginAsBooker = (booker) => {
            selectedBookerId.value = booker.accountId;
        };
        const selectedBookerAuthorized = () => {
            globalThis.$router.push(useGetLocalizedPath(`${BookingRoutes.BOOKINGS}/${BookingRoutes.ALL}`), () => globalThis.$router.go());
        };
        return {
            bookers,
            dataTable,
            selectedBookerId,
            tableLabels,
            tableSettings: computed(() => DATA_TABLE_SETTINGS),
            loginAsBooker,
            selectedBookerAuthorized,
            updateSort
        };
    }
});
