import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { orderBy } from 'lodash';
import DataTable from '@/components/DataTable.vue';
import { useGetAllSpacesQuery } from '@/generated-types/graphql.types';
import { SPACE_DATA_TABLE_SETTINGS } from '@/Admin/const';
import i18n from '@/i18n/i18n-setup';
const tableLabels = [
    {
        name: 'spaceName',
        label: i18n.t('admin.space.space_name'),
        sortable: true,
        customElement: 'spaceNameSlot'
    },
    {
        name: 'venueName',
        label: i18n.t('admin.space.venue_name'),
        sortable: true,
        customElement: 'venueNameSlot'
    },
    {
        name: 'organisationName',
        label: i18n.t('admin.space.organisation_name'),
        sortable: true,
        customElement: 'organisationNameSlot'
    },
    {
        name: 'action',
        label: i18n.t('admin.action'),
        sortable: false,
        customElement: 'actionSlot'
    }
];
export default defineComponent({
    components: {
        DataTable
    },
    setup(props, context) {
        const { emit } = context;
        const dataTable = ref([]);
        const { result } = useGetAllSpacesQuery();
        const spaces = computed(() => result?.value?.get_all_spaces || []);
        watch(() => spaces.value, () => {
            if (spaces.value) {
                dataTable.value = spaces.value.map((space) => ({
                    spaceName: space.internal_name,
                    venueName: space.venue.venue_name,
                    organisationName: space.organisation_name,
                })).sort((a, b) => a.spaceName.localeCompare(b.spaceName));
            }
        }, { immediate: true });
        const updateSort = ({ sortField, sort }) => {
            dataTable.value = orderBy(dataTable.value, [sortField], [sort]);
        };
        return {
            dataTable,
            spaces,
            tableLabels,
            tableSettings: computed(() => SPACE_DATA_TABLE_SETTINGS),
            updateSort
        };
    }
});
